import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News1004252.jpg"
import img2 from "../../assets/News/News1004252_2.jpg"
import img3 from "../../assets/News/News1004252_3.jpg"

const News1004252 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Завершил работу XIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            3-4 апреля 2025 г. в Москве прошел XIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства.
            <p></p>
            На мероприятии были представлены лекции по актуальным вопросам, связанным с защитой прав детей, организована работа площадок малого пленума, посвященных, в том числе вопросам совместной работы организаций для детей-сирот и семьи в интересах ребенка, профессиональной помощи, направленной на укрепление детско-родительских отношений, создания безопасной среды для подростков.
            <p></p>
            Значимыми событиями Всероссийского съезда стали стратегическая сессия с фокусом на перспективу развития организаций для детей-сирот и органов опеки и попечительства на основе анализа существующих вызовов и поставленных целей развития страны, сформулированных в государственных стратегических документах, а также заседание Экспертного совета <a href="https://edu.gov.ru/" style={{fontWeight: "bold"}}>Минпросвещения России</a> по вопросам опеки и попечительства в отношении несовершеннолетних граждан.
            <p></p>
        </div>
        <div>
            <img src={img2} />
        </div>
        <div>
            <img src={img3} />
        </div>
        <ToNews />
    </div>
}


export default News1004252;